:root {
    --main-bg-color: #555555;
    --bg-color: #555555;
    --main-text-color: #555555;
    --secundary-text-color: #555555;
    --question-color-text: #555555;
    --question-bg-color: #555555;
    --question--color-card: #000000;
    --success--color: #555555;
    --danger--color: #555555;
    --font-family-main: "Open Sans", sans-serif;
}

html {
    height: 100%;
    background: var(--bg-color) !important;
}

body {
    height: 100%;
    font-family: var(--font-family-main);
    background: var(--bg-color) !important;
    color: unset;
    line-height: 1.5 !important;
    font-size: 16px;
}

h2 {
    font-size: 100%;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: var(--main-bg-color);
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: var(--main-text-color);
}

.app-header--login {
    background-color: var(--header-first-page-color);
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: var(--main-text-color);
}

.App-link {
    color: #61dafb;
}

.persona {
    width: 140px;
    height: 140px;
    background: var(--main-text-color);
    border-radius: 50%;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}

.persona__img {
    object-fit: cover;
    position: absolute;
    height: 90%;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.intro__text {
    text-align: center;
    font-size: 24px;
}

.ion-input {
    background-color: #fff;
    padding: 0 15px;
    width: 230px;
    border: solid 0.5px #000;
}

.text-input {
    -moz-appearance: none;
    -ms-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0;
    display: inline-block;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    width: 92%;
    border: 0;
    background: 0 0;
    margin: 11px 8px 11px 0;
    padding: 0;
    height: 25px;
    width: calc(100% - 8px);
    font-size: initial !important;
}

.button {
    border-radius: unset;
    padding: 0 2em;
}

.button-ios-light {
    color: var(--text-action-button-color);
    background-color: var(--action-button-color);
}

.around-btn-light {
    background: none;
}

.button-ios {
    height: 2.8em;
    font-size: 1rem;
    margin: 0.4rem 0.2rem;
}

[ion-button] {
    text-decoration: none;
}

.button {
    text-align: center;
    -webkit-appearance: none;
    position: relative;
    z-index: 0;
    display: inline-block;
    text-overflow: ellipsis;
    text-transform: none;
    var--main-text-color-space: nowrap;
    cursor: pointer;
    transition: background-color, opacity 0.1s linear;
    font-kerning: none;
    user-select: none;
    contain: content;
}

button {
    border: 0;
    font-family: inherit;
    font-style: inherit;
    font-variant: inherit;
    line-height: 1;
}

.footer {
    text-align: center;
    padding: 24px 0;
}

.footer__logo {
    height: 45px;
    margin: auto;
}

.spacer {
    margin: 32px 0;
}

.button .button-inner {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0.5px;
}

.button-inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row nowrap;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.list-ios {
    margin: -1px 0 32px;
}

.persona-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 16px;
}

.persona-header__title {
    margin-top: 8px;
    padding: 10px;
    font-size: 140%;
    color: var(--question-color-text);
    padding-bottom: 0;
    margin-bottom: 0;
    font-weight: 100 !important;
}

.persona-header__title__sidebar {
    margin-top: 0;
    padding: 10px;
    color: var(--icon-text-color);
    padding-bottom: 0;
    margin-bottom: 0;
}

.persona-header__title--alert {
    margin-top: 8px;
    padding: 10px;
    font-size: 130%;
    text-align: center;
    font-weight: bold;
    line-height: 1.5;
    margin-bottom: 16px;
}

.persona-header__img-wrap {
    margin-left: 16px;
    width: 3rem;
    height: 3rem;
    background: var(--main-text-color);
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
}

.persona-header .persona-header__img {
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    height: 90%;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.persona-header__content {
    width: 100%;
    text-align: center;
}

.navbar {
    padding-bottom: 8px !important;
}

.navbar__logo-santander-auto {
    height: 45px;
    margin: auto;
}

.navbar__div {
    display: grid;
}

input:focus {
    outline: none;
}

h5 {
    font-size: 1.64rem;
    line-height: 110%;
    margin: 1.0933333333rem 0 0.656rem;
}

.light {
    font-weight: 300;
}

.s12 {
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto;
}

.center {
    text-align: center;
}

.card {
    position: relative;
    margin: 0.5rem 0 1rem;
    background-color: #fff;
    -webkit-transition: -webkit-box-shadow 0.25s;
    transition: -webkit-box-shadow 0.25s;
    transition: box-shadow 0.25s;
    transition: box-shadow 0.25s, -webkit-box-shadow 0.25s;
    border-radius: 2px;
    background: var(--question-color-card);
}

.card--sant {
    transition: 0.2s transform ease-in-out;
    min-height: 230px !important;
}

.card--sant--alert {
    transition: 0.2s transform ease-in-out;
    position: relative;
    margin: 0.5rem 0 1rem;
    background-color: #fff;
    border-radius: 2px;
    background: var(--question-color-card);
}

.card--sant__low-size {
    transition: 0.2s transform ease-in-out;
    min-height: unset !important;
}

.card__img {
    height: auto;
    max-height: 200px;
    display: block;
    border-radius: 2px 2px 0 0;
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
}

.card-image {
    padding: 12px;
}

.link-assist {
    display: flex;
    align-items: center;
    float: right;
    background-color:var(--danger--color);
    padding: 10px;
    margin: 10px 0;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    width: fit-content;
}

.link-assist:hover {
    opacity: 0.7;
    transition: all 0.8s ease;
}

.link-assist-reschedule {
    display: flex;
    align-items: center;
    float: left;
    background-color: var(--success--color);
    padding: 10px;
    margin: 10px 0;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    width: fit-content;
}

.link-assist-reschedule:hover {
    opacity: 0.7;
    transition: all 0.8s ease;
}

.row {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    width: 100%;
}

.row .col {
    float: left;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 0.75rem;
}

.row-low-size {
    float: left;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 0.75rem;
    min-height: 1px !important;
}

.col.s6 {
    left: auto;
    right: auto;
    min-height: 240px;
}

.col-alt {
    width: 50%;
    margin-left: auto;
    left: auto;
    right: auto;
}

.section {
    max-width: 650px;
    margin: auto;
}

.z-depth-1,
nav,
.card-panel,
.card,
.toast,
.btn,
.btn-large,
.btn-small,
.btn-floating,
.dropdown-content,
.collapsible,
.sidenav {
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.card-link {
    color: var(--secundary-text-color);
}

a {
    text-decoration: none !important;
    color: var(--secundary-text-color);
}

.card-content {
    padding: 0;
    text-align: center;
}

.card-content--without-img {
    padding: 20px;
    text-align: center;
}

.btn-danger {
    background-color: var(--danger--color);
    border-color: var(--danger--color);
}

.btn-danger:hover {
    background-color: var(--danger--color);
    border-color: var(--danger--color);
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show > .btn-danger.dropdown-toggle {
    color: var(--main-text-color);
    background-color: var(--danger--color);
    border-color: var(--danger--color);
}

.btn-success {
    background-color: var(--success--color);
    border-color: var(--success--color);
}

.btn-success:hover,
.btn-success:active,
.btn-primary:hover,
.btn-primary:active {
    background-color: var(--success--color) !important;
    border-color: var(--success--color) !important;
    color: var(--text-action-button-color) !important;
    box-shadow: none !important;
    opacity: 0.95;
}

.btn-primary:focus {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2) !important;
}

.btn-danger:hover,
.btn-danger:active {
    background-color: var(--danger--color) !important;
    border-color: var(--danger--color) !important;
    color: var(--main-text-color) !important;
    box-shadow: none !important;
    opacity: 0.95;
}

.btn-stop-recording {
    background-color: #999;
}

.container__div__footer--maps {
    margin-bottom: 0 !important;
}

.waves-effect {
    position: inherit;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    vertical-align: middle;
    z-index: 1;
    -webkit-transition: 0.3s ease-out;
    transition: 0.3s ease-out;
}

.btn-large {
    cursor: pointer;
    font-weight: 700;
    height: 54px;
    line-height: 54px;
    font-size: 15px;
    padding: 0 20px;
    text-decoration: none;
    color: var(--text-action-button-color);
    text-align: center;
    letter-spacing: 0.5px;
}

.margin__button-big {
    margin-right: 5px;
}

.question__img {
    height: 30vh;
    margin: 0 auto 42px;
    display: block;
}

@media (min-width: 1px) and (max-width: 600px) {
    .question-ans {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 5%;
    }
}

@media (min-width: 601px) and (max-width: 800px) {
    .question-ans {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 10%;
    }
}

@media (min-width: 801px) and (max-width: 1000px) {
    .question-ans {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 15%;
    }
}

@media (min-width: 1001px) {
    .question-ans {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 25%;
    }
}

.container {
    margin: 0 auto;
    max-width: 1024px;
    width: 100%;
}

.container-assist-detail {
    width: 100%;
    max-width: 650px;
    padding: 0 5%;
    margin: 0 auto;
}

.container__button--margin {
    margin-right: 16px;
}

.container__link--margin {
    margin-right: 8px;
}

.container__div__footer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.container__ul__li--margin {
    margin-right: 8px;
}

.collection-header {
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0;
    padding: 10px 20px;
}

.collection {
    margin: 0.5rem 0 1rem;
    border: 1px solid #e0e0e0;
    border-radius: 2px;
    overflow: hidden;
    position: relative;
    list-style-type: none;
    text-align: initial;
}

h4 {
    font-size: 2.28rem;
    line-height: 110%;
    margin: 1.52rem 0 0.912rem;
    font-weight: 400;
}

.collection--acionamento h4 {
    color: #cb0e00;
    text-align: left;
}

.card--no-pad,
.card--no-pad .card-content {
    padding: 0 !important;
    max-width: 650px;
    margin: auto;
}

.collection--acionamento .collection-item {
    padding: 12px !important;
}

.collection .collection-item {
    background-color: var(--question--color-card);
    color: var(--secundary-text-color);
    line-height: 1.5rem;
    padding: 10px 20px;
    margin: 0;
    border-bottom: 1px solid #e0e0e0;
}

.with-header {
    padding-left: 0;
}

.hspace-between {
    justify-content: space-between;
    padding: 8px;
}

.valign-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.fade-appear {
    z-index: 1;
    transform: translateX(33%);
}

.fade-appear.fade-appear-active {
    transform: translateX(0);
    will-change: transform, -webkit-transform, opacity;
    transition-duration: 800ms;
    transition-timing-function: cubic-bezier(0.36, 0.66, 0.04, 1);
}

.toolbar {
    position: initial;
    width: 100%;
    background-color: var(--main-bg-color);
    height: 56px;
}

.toolbar-back-button {
    padding: 1rem;
    color: var(--icon-text-color);
}

.toolbar__navigation {
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 1rem;
}

.toolbar__logo a {
    color: var(--main-text-color);
    text-decoration: none;
}

.toolbar__logo {
    margin-left: 1rem;
}

.toolbar__navigation-itens ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.toolbar__navigation-itens li {
    padding: 0 0.5rem;
}

.toolbar__navigation-itens a {
    color: var(--main-text-color);
    text-decoration: none;
}

.toolbar__navigation-itens a:hover,
.toolbar__navigation-itens a:active {
    color: orange;
    text-decoration: none;
}

.toggle-button {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 24px;
    width: 30px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
}

.toggle-button:focus {
    outline: none;
}

.toggle-button__line {
    height: 2px;
    width: 30px;
    background: var(--icon-text-color);
}

.side-drawer {
    height: 100%;
    background: var(--question-bg-color);
    box-shadow: 1px 0 7px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    width: 70%;
    max-width: 250px;
    z-index: 200;
    transform: translateX(100%);
    transition: transform 0.3s ease-out;
    overflow: auto;
}

.side-drawer.open {
    transform: translateX(0);
}

.side-drawer ul {
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: inherit;
    margin: 0;
}

.side-drawer li {
    margin: 5px;
}

.side-drawer a {
    color: var(--question-color-text);
    text-decoration: none;
    font-size: 90%;
}

.side-drawer a:hover,
.side-drawer a:active {
    color: #333;
}

.backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
    top: 0;
    left: 0;
}

.toolbar__link {
    width: 70%;
    margin: 0px auto;
}

.btn__answer--yes {
    background-color: #28a745;
    color: var(--main-text-color);
}

.btn__answer--no {
    background-color: var(--danger--color);
    color: var(--main-text-color);
}

.list__img {
    height: 50px;
}

.list__position {
    display: flex;
}

.list__text {
    margin-bottom: auto;
    margin-top: auto;
    margin-left: 10px;
}

.slidecontainer {
    width: 100%;
}

.slider {
    -webkit-appearance: none;
    width: 85%;
    height: 25px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    margin: auto;
}

.slider__image {
    overflow: hidden;
    height: 35vh;
}

.slider:hover {
    opacity: 1;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: var(--main-bg-color);
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: var(--main-bg-color);
    cursor: pointer;
}

.alert-card {
    text-align: center;
    padding: 50px;
}

.input__address {
    width: 100%;
    border: 0.5px solid;
    text-align: center;
    padding: 1px;
    margin-top: 16px;
}

.proposal-main {
    border: solid 2px #dd231f;
    min-height: 100px;
    display: flex;
}

.proposal-secundary {
    border: solid 2px #fff720;
    min-height: 100px;
    display: flex;
}

.proposal {
    border: solid 2px #e0e0e0;
    min-height: 100px;
    display: flex;
}

.proposal__text {
    height: fit-content;
    margin: auto;
}

.slider {
    margin-bottom: 90px;
    opacity: 1 !important;
    background: none;
}

.slider__text {
    text-align: center;
    font-size: 115%;
    font-weight: 700;
    width: fit-content;
    margin: auto;
    border-bottom: var(--main-bg-color) solid 2px;
    min-height: 55px;
}

.gas__img {
    transform: translateX(300px);
    transition: transform 1s ease;
    height: 0;
}

.gas__img--active {
    transform: translateX(0px);
    transition: transform 1s ease;
    height: 30vh;
    margin: 0 auto;
    display: block;
}

.text-center {
    text-align: center;
}

.rangeslider-horizontal .rangeslider__handle:after {
    position: unset !important;
}

.iframe {
    border: none;
    width: 100%;
    position: fixed;
    height: calc(100vh - 56px);
}

.content-header {
    padding-bottom: 15px;
    background: var(--question-bg-color);
    max-width: 650px;
    margin: auto;
}

.div-input {
    border: solid 1px;
    padding: 10px;
    width: 300px;
    height: 100px;
    margin: auto;
    background: #fff;
}

.reload {
    width: 300px;
    margin: auto;
    text-align: right;
}

.color-red {
    color: var(--danger--color);
}

#cover {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 9999;
}

.contacts {
    text-align: center;
    padding: 16px;
}

.contacts__text {
    text-align: center;
    padding: 8px;
    margin: 0;
}

.error__text {
    text-align: center;
    padding: 10px;
    margin: 5px 5px 25px 5px;
    background-color: var(--danger--color);
    border-radius: 10px;
}

.loader__position {
    margin-left: auto;
    margin-top: 20vh;
    width: 100%;
    display: flex;
    justify-content: center;
}

.map__button {
    margin: auto;
    margin-top: 69vh;
}

.list__question {
    width: 100%;
}

.list__question--padding {
    padding: 8px;
}

.personal-info {
    padding: 16px;
}

.personal-info--input {
    width: unset !important;
}

.personal-info--select {
    background-color: #fff;
    padding: 10px 15px;
    -webkit-appearance: none;
    border-radius: unset;
    width: 100%;
    border: solid 0.5px;
}

.text-center {
    text-align: center;
}

.react-datetime-picker__wrapper {
    width: 100%;
    border: none !important;
}

.accompaniment__text--main {
    margin: auto;
    display: grid;
    text-align: center;
}

.accompaniment__text--secundary {
    margin-left: auto;
}

.assist-detail {
    width: 80%;
    margin: auto;
}

.direction-maps {
    display: table;
    margin: auto;
}

.react-datetime-picker__calendar,
.react-datetime-picker__clock {
    z-index: 2 !important;
}

.flex-itens {
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    display: flex;
}

.flex-itens-card {
    min-height: 180px;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: var(--question--color-card);
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    width: 100%;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    -ms-flex-preferred-size: 0;
    -ms-flex-positive: 1;
    margin: 3%;
}

.login-container {
    height: calc(100vh - 56px);
    background-color: var(--header-first-page-color);
}

.input--map {
    height: 15px !important;
    font-size: 15px !important;
    outline: none;
}

.list__text--accompaniment {
    margin-bottom: auto;
    margin-top: auto;
}

.padding-15 {
    padding: 15px;
}

.react-datepicker__month-container {
    width: 260px !important;
}

.react-datepicker__time-container {
    width: 50px !important;
}

.react-datepicker__header--time {
    height: 0px !important;
    padding: 0 !important;
}

.react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    width: 50px !important;
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
    width: 2.9rem !important;
    line-height: 2.9rem !important;
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
    width: 1.9rem !important;
    line-height: 1.9rem !important;
}

.undefined {
    width: 100%;
    background-color: #fff;
    padding: 0 15px;
    border: solid 0.5px;
    height: 48px;
    border-radius: unset !important;
}

/* .undefined:focus {
    display: none;
} */

.app-header-container {
    min-height: 100vh;
    position: fixed;
    top: 0;
    color: white;
    background: #555555;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 60px !important;
}

.react-datepicker__portal .react-datepicker__navigation--next:focus {
    outline: none;
}

.react-datepicker__portal .react-datepicker__navigation--previous:focus {
    outline: none;
}

.home-wallet--card {
    background: var(--question--color-card) !important;
}

.accordion-container {
    width: 100%;
}

.accordion-container > .card {
    background-color: var(--card-faq-color);
    color: var(--question-faq-color);
}

.accordion-container > .card > .collapse,
.collapsing {
    background: var(--card-faq-color) !important;
    color: var(--answer-faq-color);
}

.back-button {
    width: 60px;
    height: 48px;
}

.drower-button {
    width: 39px;
}

#modalRescheduling {
    margin: 0 !important;
    max-width: unset !important;
}

button:focus {
    outline: none !important;
}

.single-choice-with-image {
    position: relative;
    top: 2em;
    height: 1px;
}

.record-btn {
    display: block;
    background: var(--success--color);
    padding: 8px;
    margin: 8px auto;
    color: white;
    width: 185px;
}

.conclude-assist-container {
    display: grid;
    max-width: 650px;
    padding: 0 5%;
    margin: auto;
}

.content-message-password {
    margin: 0px auto;
    background: #9497a0fa;
    text-align: center;
    padding: 15px;
    color: #fff;
    font-size: 17px;
    font-weight: bold;
}

.content-message-password span {
    cursor: pointer;
    width: 25px;
    float: right;
    background: #696969;
    border-radius: 50%;
}


@media (min-width: 1px) and (max-width: 600px) {
    .content-message-password {
        width: 100%;
    }
}

.divisor-line {
    height: 1px;
    background: var(--question-bg-color);
    width: 5vh;
}

.conclude-alert-label {
    font-size: 23px;
    font-weight: bold;
}

.conclude-label {
    opacity: 0.8;
}

.map__button--distance {
    margin-top: 10px;
}

.margin--botton__35 {
    margin-bottom: 35px;
}

.section-container {
    width: 44%;
    display: flex;
    flex-direction: column;
    margin: auto;
    color: var(--secundary-text-color);
}

.modal-content {
    max-width: 650px !important;
    margin: auto !important;
}

.modal-footer-breakline {
    flex-wrap: wrap;
}

.modal-footer-breakline > .btn-success {
    margin: 5px;
    width: 150px;
}

.modal-footer-breakline > .btn-danger {
    margin: 5px;
    width: 225px;
}

.phone-number-weight {
    font-weight: bold;
}

#success-back-buttom {
    position: absolute;
    z-index: 1000;
}

.sidebar-btn {
    cursor: pointer;
    margin: 15px 0;
}

.card-wallet {
    height: 50px;
    overflow: hidden;
    margin-bottom: 15px;
}

.card-wallet h2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.chassi-message {
    margin-top: -20px;
    font-size: 14px;
}

small .tel-link {
    color: var(--main-text-color);
    font-weight: bold;
}

.form-container {
    margin-bottom: 16px;
}

.error-label {
    margin-top: 6px;
    text-align: center;
    color: var(--danger--color);
}

.react-datepicker__time-list {
    overflow-y: unset !important;
}

::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar {
    width: 3px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #000000;
}

.main-text-color {
    color: var(--main-text-color);
}

.text-action-button-color {
    color: var(--text-action-button-color);
}

.info-error-container {
    width: 100%;
    text-align: center;
}

.faq-answer-label {
    font-size: 16px !important;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.react-datepicker__time-box {
    scrollbar-width: thin;
    -ms-overflow-style: none;
}

.error-size-text {
    font-size: 19.16px !important;
}

.text-color-black {
    color: var(--question--color-card);
}

.question-color-text {
    color: var(--text-field-color);
}

ul.remove-border {
    border: none;
}

.list-item-space {
    padding: 4px;
}

.list-collection-item {
    border: 1px solid #e0e0e0;
}

.faq-text-colot {
    color: var(--question-color-text);
}

.contact-container {
    display: flex;
    flex-direction: column;
    max-width: 650px;
    margin: auto;
    align-items: center;
    margin-bottom: 14px;
}

.contact-item {
    color: var(--question-color-text);
}

.contact-title {
    font-size: 20px;
    font-weight: bold;
}

.contact-description {
    font-size: 14px;
}

.contact-list {
    overflow: auto !important;
}

.content-conclude-wrapper {
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
}

.assist-statement {
    border-collapse: collapse;
    margin-bottom: 48px;
    width: 100%;
    border: 1px solid;
}

.assist-statement thead {
    background: var(--action-button-color);
    font-weight: 600;
}

.assist-statement td {
    padding: 16px;
}

.assist-statement thead td {
    border: none;
    color: var(--text-action-button-color);
}

.assist-statement tbody td {
    color: var(--question-color-text);
    border: 1px solid;
}

.assist-statement-event {
    text-transform: uppercase;
}

.datebox {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
}

.datepicker-wrapper + .datepicker-wrapper {
    margin-top: 16px;
}

@media screen and (min-width: 620px) {
    .datebox {
        flex-direction: row;
    }

    .datepicker-wrapper + .datepicker-wrapper {
        margin-top: 0;
        margin-left: 32px;
    }

}

.datepicker-wrapper {
    flex-direction: column;
    flex: 1;
}

/* REACT DATEPICKER PERSONALIZAÇÃO */

.react-datepicker-wrapper {
    display: block !important;
}

.react-datepicker__current-month {
    padding: 8px 0;
    border-bottom: 1px solid #eee;
}

.react-datepicker__header {
    background-color: transparent !important;
    border: none !important;
}

.react-datepicker__navigation {
    margin: 8px 0;
}


.react-datepicker__day-names {
    padding: 4px 0;
}

.react-datepicker__time-container {
    width: 200px !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    margin: 0 !important;
    width: 100% !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    display: flex;
    align-items: center;

    padding: 24px !important;
    text-align: left;
    font-size: 14px;
}

.react-datepicker__triangle {
    display: none;
}

.datepicker-wrapper > div .react-datepicker__input-container input:disabled {
    border: none;
    padding: 0;
    color: var(--question-color-text);
    background: none;
}

.react-datepicker-popper {
z-index: 999 !important;
}

.react-datepicker__input-container input:disabled::placeholder {
    color: var(--main-text-color);
}

.progress-bar-product {
    background-color: var(--main-text-color) !important;
}

.progress-bar {
    background-color: var(--main-bg-color) !important;
}

.progress-bar-text {
    margin-bottom: 3px;
}

.modal-text {
    color: var(--main-text-color) !important;
}